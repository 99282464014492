import React, { useState, useEffect, ReactElement } from 'react';

import { ListProvider } from '../../models/interfaces/ListProvider';
import { Spinner, ListGroup } from 'react-bootstrap';

interface ListItemTranslator<T> {
    (resolver: T): ReactElement;
}

interface Props<T> {
    listProvider: ListProvider<T>;
    listItemTranslator: ListItemTranslator<T>;
    isFlush?: boolean;
}

export default function ListView<T>({ listProvider, listItemTranslator, isFlush }: Props<T>) {
    const [resolvers, setResolvers] = useState<Array<T> | undefined>(undefined);
    useEffect(() => {
        const listenerToken = listProvider.addListener(updatedResolvers => {
            setResolvers(updatedResolvers);
        });
        return () => {
            listProvider.removeListener(listenerToken);
        };
    }, [listProvider]);
    if (!resolvers) {
        return <Spinner animation="border" size="sm" role="status" aria-hidden="true" />;
    } else {
        return (
            <ListGroup className="myListView d-flex flex-grow-1" variant={isFlush ? 'flush' : undefined}>
                {resolvers.map(resolver => {
                    return listItemTranslator(resolver);
                })}
            </ListGroup>
        );
    }
}
