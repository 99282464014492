import { Spinner } from "react-bootstrap";
import { Match, MatchStatus } from "../../../models/interfaces/Match";
import { CompetitionFilterProps, CompetitionFilterView } from "./CompetitionPickerViews";
import { useMatchScore } from "../../../helpers/useMatchStats";
import { SportProvider } from "../../../models/interfaces/SportProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import styled from "styled-components";
import { Team } from "../../../models/interfaces/Team";
import { MatchEntry } from "../../../models/interfaces/MatchEntry";


interface Props {
    sportProvider: SportProvider,
    filter: CompetitionFilterProps;
    matches: Match[] | undefined;
    noContentText: string;
}

export function EventMatchDisplayView({ sportProvider, filter, matches, noContentText }: Props) {
    const selectedCompetitionEntry = filter.teamSelect.selectedCompetitionEntry
    return (
        <div style={{ paddingTop: '10px' }}>
            <CompetitionFilterView
                competitionSelect={filter.competitionSelect}
                teamSelect={filter.teamSelect}
            />
            <div style={{ width: '100%', paddingTop: '10px' }}>
                {matches ? (
                    matches.length > 0 ? (
                        <div className="matchContainer">
                            {matches.filter(match => {
                                if (selectedCompetitionEntry) {
                                    return match.lightCapTeam.id === selectedCompetitionEntry.id || match.darkCapTeam.id === selectedCompetitionEntry.id
                                } else {
                                    return true
                                }
                            }).map((match) => (
                                <div className="matchElement">
                                    <a
                                        key={match.id}
                                        href={`/matches/${match.id}`}>
                                        <MatchDisplay sportProvider={sportProvider} match={match} />
                                    </a>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div className="matchContainer">{noContentText}</div>
                    )
                ) : (
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                )}
            </div>
        </div>
    );
}

interface MatchDisplayProps {
    sportProvider: SportProvider,
    match: Match;
}

const FlexibleContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    
    padding: 10px;
    gap: 10px;

    @media only screen and (max-width: 450px) {
        flex-direction: column;
        gap: 5px;
        padding: 5px;
    }
`

const CompetitionDetailWrapper = styled.div`
    @media only screen and (max-width: 750px) {
        display: none;
    }
`;

const LargeScreenVenueView = styled.div`
    display: flex; 
    flex-direction: column;
    justify-content: center;
    align-items: center;

    min-width: 100px;

    @media only screen and (max-width: 450px) {
        display: none;
    }
`

const ButtonWrapper = styled.div`
    @media only screen and (max-width: 600px) {
        display: none;
    }
`;

function MatchDisplay({ sportProvider, match }: MatchDisplayProps) {
    const { lightTeamScore, darkTeamScore } = useMatchScore({ match });

    return <FlexibleContainer>
        <MatchMetadataView match={match} />
        <div className="disclosureIndicator">
            <TeamScoreView 
                lightCapTeam={match.lightCapTeam} darkCapTeam={match.darkCapTeam} 
                lightCapScore={lightTeamScore} darkCapScore={darkTeamScore}
            />
        </div>
        <CompetitionDetailWrapper style={{marginRight: 'auto' }}>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <span style={{ color: 'lightgrey' }}>{match.competition.attributes.name}</span>
                <span style={{ color: 'lightgrey' }}>{sportProvider.displayTextForTeamOrCompetitionAttributes(match.competition.attributes)}</span>
            </div>
        </CompetitionDetailWrapper>
        { match.venue &&
            <LargeScreenVenueView style={{marginLeft: 'auto' }}>
                <span style={{ color: 'lightgrey' }}>{match.venue.name}</span>
                <span style={{ color: 'lightgrey' }}>{match.venue?.subvenueName}</span>
            </LargeScreenVenueView>
        }
        <ButtonWrapper style={{marginLeft: 'auto'}}>
            <div style={{
                display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'stretch',
            }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }}>
                    <span className="disclosureIndicator">Details</span>
                    <FontAwesomeIcon icon={faChevronRight as IconProp} style={{ height: '10px', paddingLeft: '4px', paddingBottom: '2px' }} />
                    
                </div>
            </div>
        </ButtonWrapper>
        
    </FlexibleContainer>
}

const TeamScoreContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 10px;
    min-width: 200px;
    max-width: 230px;

    @media only screen and (max-width: 500px) {
        justify-content: space-between;
        max-width: 500px;
    }
`

const TeamContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-width: 150px;
`

const ScoreContainer = styled.div`
    display: flex;
    flex-direction: column;
`

function TeamScoreView({ lightCapTeam, darkCapTeam, lightCapScore, darkCapScore }: {lightCapTeam: MatchEntry, darkCapTeam: MatchEntry, lightCapScore ?: number, darkCapScore ?: number}) {
    const boldLight = typeof lightCapScore === 'number' && typeof darkCapScore === 'number' && lightCapScore > darkCapScore;
    const boldDark = typeof lightCapScore === 'number' && typeof darkCapScore === 'number' && darkCapScore > lightCapScore;
    return <TeamScoreContainer>
        <TeamContainer>
            <span style={{fontWeight: boldLight ? 'bolder' : 'normal' }}>{lightCapTeam.name}</span>
            <span style={{fontWeight: boldDark ? 'bolder' : 'normal' }}>{darkCapTeam.name}</span>
        </TeamContainer>
        {
            typeof lightCapScore === 'number' && typeof darkCapScore === 'number' && 
            <ScoreContainer>
                <span style={{fontWeight: boldLight ? 'bolder' : 'normal' }}>{lightCapScore}</span>
                <span style={{fontWeight: boldDark ? 'bolder' : 'normal' }}>{darkCapScore}</span>
            </ScoreContainer>
        }
    </TeamScoreContainer>
}

const MetadataContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 2px;

    min-width: 90px;
    padding-right: 10px;

    border-right: solid;
    border-right-color: darkgrey;
    
    @media only screen and (max-width: 450px) {
        flex-direction: column;
        align-items: center;
        border-right: none;
        min-height: 0px;
    }
`

const MetadataItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: 450px) {
        flex-direction: row;
        border-right: none;
        gap: 10px;
        min-height: 0px;
    }
`

const MetadataVenueView = styled.div`
    display: none;
    
    @media only screen and (max-width: 450px) {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        min-width: 90px;
        gap: 10px;
    }
`

function MatchMetadataView({ match }: { match: Match }) {
    return (
    <MetadataContainer>
        { match.venue &&
            <MetadataVenueView>
                <span style={{ color: 'lightgrey' }}>{match.venue.name}{match.venue.subvenueName ? `, ${match.venue.subvenueName}`: ''}</span>
            </MetadataVenueView>
        }
        <MetadataItemContainer>
            {
                match.status === MatchStatus.inProgress ?
                    <i style={{ color: 'white' }}>In Progress</i>
                    :
                    <>
                        <span style={{ color: 'white' }}>{match.date.toLocaleDateString()}</span>
                        <span style={{ color: 'white' }}>
                            {match.date.toLocaleTimeString([], {
                                hour: '2-digit',
                                minute: '2-digit',
                            })}
                        </span>
                    </>
            }
        </MetadataItemContainer>
    </MetadataContainer>
    )
}