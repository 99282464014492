import { Carousel, Col, Row } from "react-bootstrap";

interface Sponsor {
    name: string;
    link: string;
    image: string;
}

interface Props {
    sponsors: Sponsor[];
}

export function SponsorBanner({ sponsors }: Props) {
    const groupsOfThree = sponsors.reduce((resultArray, item, index) => { 
        const chunkIndex = Math.floor(index/3)
        if(!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = [] // start a new chunk
        }
        resultArray[chunkIndex].push(item)
        return resultArray
    }, new Array<Sponsor[]>())

    return (
        <Col className="sponsorBanner">
            <h3>Partners</h3>
            <hr />
            <Carousel style={{height: '120px'}}> 
                {
                    groupsOfThree.map((group, index) => {
                        return (
                            <Carousel.Item key={index} className="px-5">
                                <Row className="justify-content-center">
                                    {
                                        group.map((sponsor) => {
                                            return (
                                                <Col key={sponsor.name} className="mb-2">
                                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                                        <a href={sponsor.link}>
                                                        <img style={{ height: '80px', width: '80px', display: 'block', objectFit: 'contain'}}
                                                            src={sponsor.image}
                                                            alt={sponsor.name}
                                                        />
                                                        </a>
                                                    </div>
                                                </Col>
                                            )
                                        })
                                    }
                                </Row>
                            </Carousel.Item>
                        )
                    })
                }
            </Carousel>
        </Col>
    )
}